import _defineProperties from "define-properties";
import _callBind from "call-bind";
import _implementation from "./implementation";
import _polyfill from "./polyfill";
import _shim from "./shim";
var exports = {};
var define = _defineProperties;
var callBind = _callBind;
var implementation = _implementation;
var getPolyfill = _polyfill;
var shim = _shim;
var polyfill = callBind(getPolyfill(), Object);
define(polyfill, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
exports = polyfill;
export default exports;