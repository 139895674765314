var exports = {};

var numberIsNaN = function (value) {
  return value !== value;
};

exports = function is(a, b) {
  if (a === 0 && b === 0) {
    return 1 / a === 1 / b;
  }

  if (a === b) {
    return true;
  }

  if (numberIsNaN(a) && numberIsNaN(b)) {
    return true;
  }

  return false;
};

export default exports;