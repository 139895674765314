import _polyfill from "./polyfill";
import _defineProperties from "define-properties";
var exports = {};
var getPolyfill = _polyfill;
var define = _defineProperties;

exports = function shimObjectIs() {
  var polyfill = getPolyfill();
  define(Object, {
    is: polyfill
  }, {
    is: function testObjectIs() {
      return Object.is !== polyfill;
    }
  });
  return polyfill;
};

export default exports;